<template>
<div>
  <div class="b-profile-form p-3 pb-5 mb-5">
    <form @submit.prevent="saveProfile">
      <b-form-group label="Foto Profil" description="Tap untuk mengubah foto">
        <label for="fm-foto" class="w-100" ref="previewImage">
          <b-img-lazy :src="(form.data.foto_karyawan) ? form.data.foto_karyawan : 'https://dummyimage.com/300x300/eeeeee/000000'" fluid class="w-100"/>
          <input type="file" ref="fm-foto" hidden id="fm-foto"
          @change="previewImage($event)" accept="image/*" disabled> 
        </label>
      </b-form-group>
      <b-form-group label="Nik">
        <b-input v-model="form.data.nik" disabled/>
      </b-form-group>
      <b-form-group label="Status Karyawan">
        <b-input v-model="form.data.status_karyawan" disabled/>
      </b-form-group>
      <b-form-group label="Cabang">
        <b-input v-model="form.data.from_branch" disabled/>
      </b-form-group>
      <b-form-group label="Jabatan">
        <b-input v-model="form.data.from_position" disabled/>
      </b-form-group>
      <b-form-group label="Nama Lengkap">
        <b-input v-model="form.data.fullname"/>
      </b-form-group>
      <b-form-group label="No Ktp">
        <b-input v-model="form.data.no_ktp"/>
      </b-form-group>
      <b-form-group label="Tanggal Lahir">
        <b-input v-model="form.data.tgl_lahir"/>
      </b-form-group>
      <b-form-group label="Jenis Kelamin">
        <b-input v-model="form.data.jk" disabled/>
      </b-form-group>
      <b-form-group label="Status Pernikahan">
        <b-select v-model="form.data.from_pernikahan" :options="opt.from_pernikahan"/>
      </b-form-group>
      <b-form-group label="Jumlah Anak">
        <b-input v-model="form.data.jumlah_anak"/>
      </b-form-group>
      <b-form-group label="Golongan Darah">
        <b-input v-model="form.data.golongan_darah"/>
      </b-form-group>
      <b-form-group label="Tempat Lahir">
        <b-input v-model="form.data.tmp_lahir"/>
      </b-form-group>
      <b-form-group label="Tanggal Lahir">
        <b-form-datepicker v-model="form.data.tgl_lahir"/>
      </b-form-group>
      <b-form-group label="No Npwp">
        <b-input v-model="form.data.npwp"/>
      </b-form-group>
      <b-form-group label="No Hp">
        <b-input v-model="form.data.no_hp"/>
      </b-form-group>
      <b-form-group label="Email">
        <b-input v-model="form.data.email"/>
      </b-form-group>
      <b-form-group label="Alamat">
        <b-textarea rows="3" v-model="form.data.alamat" no-resize/>
      </b-form-group>
      <h4 class="my-3">Pendidikan</h4>
      <b-form-group label="Pendidikan Terakhir">
        <b-textarea rows="3" v-model="form.data.pendidikan_terakhir" no-resize/>
      </b-form-group>
      <b-form-group label="Pendidikan Berjalan">
        <b-textarea rows="3" v-model="form.data.pendidikan_berjalan" no-resize/>
      </b-form-group>
      <b-form-group label="Pengalaman Kerja">
        <b-textarea rows="3" v-model="form.data.pengalaman_kerja" no-resize/>
      </b-form-group>
      <b-form-group label="Sertifikat">
        <b-textarea rows="3" v-model="form.data.sertifikat" no-resize/>
      </b-form-group>
      <b-row>
        <b-col>
          <router-link to="/profile">
            <b-button variant="secondary" block>Batal</b-button>
          </router-link>
        </b-col>
        <b-col>
          <b-button
            block
            class="w-100"
            variant="primary"
            type="submit"
            :disabled="form.loading"
            >{{ form.loading ? "Memproses..." : "Simpan" }}</b-button
          >
        </b-col>
      </b-row>
    </form>
  </div>
</div>
</template>
<script>
import axios from "@/axios";
import {
  mapGetters,
  mapActions
} from "vuex";
export default {
  data(){
    return {
      passView: false,
      form: {
        data: {
          nik: '-',
          status_karyawan: '-',
          fullname: '-',
          no_ktp: '-',
          alamat: '-',
          jk: '-',
          golongan_darah: '-',
          tmp_lahir: '-',
          tgl_lahir: '-',
          from_pernikahan: '-',
          jumlah_anak: 0,
          npwp: '-',
          no_hp: '-',
          email: '-',
          pendidikan_terakhir: '-',
          pendidikan_berjalan: '-',
          pengalaman_kerja: '-',
          sertifikat: '-',
          foto_karyawan: null
        },
        loading: false
      },
      opt: {
        from_pernikahan: [
          {
            text: "Lajang",
            value: 0
          },
          {
            text: "Menikah",
            value: 1
          },
          {
            text: "Lainnya",
            value: 2
          },
        ],
        jk: [
          {
            text: "Laki-Laki",
            value: "L"
          },
          {
            text: "Perempuan",
            value: "P"
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  watch: {
    user(val){
      let user = val
      if(user && user.token && user.cif_no){
        this.$router.push("/");
      } else {
        this.$router.push("/login");
      }
    },
  },
  methods: {
    ...mapActions(["logout"]),
    async getProfile(){
      this.form.loading = true;
      let url = `presence/profile_staff`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      }
      let payload = new FormData();
      payload.append('nik',this.user.data.nik)
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let userData = this.user.data
        let resdata = req.data.msg
        this.form.data = {
          nik: resdata.nik,
          status_karyawan: resdata.status,
          fullname: resdata.fullname,
          no_ktp: resdata.no_ktp,
          alamat: resdata.alamat,
          jk: resdata.jk,
          golongan_darah: (resdata.golongan_darah) ? resdata.golongan_darah : '-',
          tmp_lahir: resdata.tmp_lahir,
          tgl_lahir: resdata.tgl_lahir,
          from_pernikahan: resdata.from_pernikahan,
          jumlah_anak: (resdata.jumlah_anak) ? resdata.jumlah_anak : 0,
          npwp: (resdata.npwp) ? resdata.npwp : '-',
          no_hp: resdata.no_hp,
          email: (resdata.email) ? resdata.email : '-',
          pendidikan_terakhir: (resdata.pendidikan_terakhir) ? resdata.pendidikan_terakhir: '-',
          pendidikan_berjalan:(resdata.pendidikan_berjalan) ? resdata.pendidikan_berjalan : '-',
          pengalaman_kerja:(resdata.pengalaman_kerja) ? resdata.pengalaman_kerja : '-',
          sertifikat: (resdata.sertifikat) ? resdata.sertifikat : '-',
          foto_karyawan: userData.foto,
          from_branch: resdata.from_branch,
          from_position: resdata.from_position
        }
        this.form.loading = false;
      } else {
        this.form.loading = false;
      }
    },
    async saveProfile(){
      this.form.loading = true;
      let url = `presence/edit_profile`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      }
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        this.notif("Profil berhasil diupdate", "Sukses", "success")
        setTimeout(()=>{
          this.$router.push('/profile')
        },2000)
        this.form.loading = false;
      } else {
        this.form.loading = false;
      }
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-full",
      });
    },
    previewImage(event) {
      let theImg = null
      let vm = this
      const foto = this.$refs['fm-foto']
      let reader = new FileReader();
      theImg = event.target.files[0];
      reader.readAsDataURL(theImg);
      reader.onload = function () {
        vm.form.data.foto_karyawan = reader.result
        foto.type = 'text';
        foto.type = 'file';
      };
      reader.onerror = function () {
        vm.form.data.foto_karyawan = null
        foto.type = 'text';
        foto.type = 'file';
      };
    },
  },
  mounted() {
    this.getProfile()
  }
}
</script>
